body {
  margin: 0%;
  font-family: Poppins;
  text-decoration: none;
}

.revnitrobodyblack {
  background-color: #000000;
}
a {
  text-decoration: none;
}
/* banner//////////////////////////////////////////////////////////////////////// */
.container {
  position: relative;
  width: 100%;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  width: 100%;
  font-weight: 500;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.container:hover .overlay {
  height: 100%;
}
.image-text-center {
  font-size: 100px;
  margin-top: 46px;
}

/* card css/////////////////////////////////////////////////////////////////////////// */
.content {
  display: none;
}

.content.active {
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 60px;
}
.button-fl {
  padding: 60px 80px;
  width: 1360px;
}
.button-center-div {
  display: flex;
  justify-content: center;
}
.icon-formeo {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px 0px 0px;
}
.dfietbisb {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.osnofnsl {
  margin-top: -10px;
}
.iconpari {
  display: flex;
  align-items: center;
}
.iconds {
  margin: 0%;
  color: #232b70;
  font-size: 19px;
  margin-left: 10px;
  font-weight: 500;
}
.ioocin {
  width: 25px;
  height: 25px;
}
.imageinsofn {
  width: 351px;
  height: 243px;
}
.paraidnfons {
  margin-top: 0px;
  height: 105px;
  overflow: auto;
  font-size: 17px;
  line-height: 28px;
}
.upcomingbutton1 {
  background-color: white;
  color: #232b70;
  font-size: 18px;
  padding: 11px 41px 11px 41px;
  box-shadow: 0px 0px 7px 0px #00000026;
  border: none;
  border-radius: 8px;
}

.upcomingbutton1.active {
  background-color: #232b70;
  color: white;
  font-size: 18px;
  margin-right: 20px;
  padding: 11px 41px 11px 41px;
}
.upcomingbutton {
  background-color: white;
  color: #232b70;
  margin-right: 20px;
  font-size: 18px;
  padding: 11px 41px 11px 41px;
  box-shadow: 0px 0px 7px 0px #00000026;
  border: none;
  border-radius: 8px;
}

.upcomingbutton.active {
  background-color: #232b70;
  color: white;
  font-size: 18px;
  padding: 11px 41px 11px 41px;
}

.pastevents {
  background-color: white;
  color: #232b70;
  padding: 11px 41px 11px 41px;
}

.pastevents.active {
  background-color: blue;
  color: white;
  padding: 11px 41px 11px 41px;
}
button.active {
  background-color: blue;
  color: white;
  padding: 11px 41px 11px 41px;
}
/* 
  .button-container {
    text-align: center;
  } */

.button {
  margin: 0 10px 10px 0px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none; /* Remove underline for anchor elements */
  color: #fff; /* Set text color */
  background-color: #000b66; /* Set background color */
  border: 1px solid #007bff; /* Add border */
  border-radius: 4px; /* Add border radius for rounded corners */
}

.button:hover {
  background-color: #0056b3; /* Change background color on hover */
  border-color: #0056b3; /* Change border color on hover */
}

.bold {
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.blog-posts {
  padding: 20px;
  max-width: 1360px;
  font-family: "Poppins";
}

.heading-section {
  display: flex;
  flex-wrap: wrap;
}

.latest-news {
  width: 63%;
  background-color: #f6f6f6;
  box-sizing: border-box;
  padding: 20px;
  margin: 20px;
}

.heading {
  color: #242c71;
}
.heading2 {
  margin-top: 20px;
  font-size: 18px;
  color: #242c71;
  font-family: "Poppins";
}
.cardzz {
  display: flex;
  flex-wrap: nowrap;
  /* height: 243px; */
  flex: 48%;
  flex-direction: row;
  background-color: white;
  box-shadow: 0px 1px 5px 0px #0000001a;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
}
.card2 {
  display: flex;
  flex-wrap: wrap;
  height: 111px;
  flex: 48%;
  flex-direction: row;
  background-color: white;
  box-shadow: 0px 1px 5px 0px #0000001a;

  margin-bottom: 20px;
  overflow: hidden;
}
.card-content h3 {
  margin-top: 0;
  font-size: 20px;
}

.card-content2 h3 {
  margin-top: -5px;
  margin-bottom: -5px;
  font-size: 14px;
}

.card-content2 p {
  margin-bottom: -5px;
  font-size: 10px;
}

.card-content a {
  text-decoration: none;
  color: #007bff;
}

.card-content2 a {
  text-decoration: none;
  color: #007bff;
}

.card-content a:hover {
  text-decoration: underline;
}

.card-content2 a:hover {
  text-decoration: underline;
}

.author-info {
  font-style: italic;
  color: #888;
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
}

.card-content {
  flex: 60%;
  padding: 20px;
  box-sizing: border-box;
}

.card-content2 {
  flex: 60%;
  padding: 15px;
  box-sizing: border-box;
}
.card-image {
  /* width: 351px; */
  height: 243px;
}
.dete {
  margin-left: 10px;
}
.card-image img {
  width: 351px;
  height: 243px;
  object-fit: cover;
  border-radius: 10px;
}

/* Media Query for larger screens */

@media (min-width: 320px) and (max-width: 500px) {
  .blog-posts {
    padding: 10px 0px;
  }
  .button {
    margin: 0 10px 10px 0px;
    padding: 7px 3px;
    font-size: 8.5px;
    cursor: pointer;
    text-decoration: none; /* Remove underline for anchor elements */
    color: #fff; /* Set text color */
    background-color: #000b66; /* Set background color */
    border: 1px solid #007bff; /* Add border */
    border-radius: 4px; /* Add border radius for rounded corners */
  }

  .latest-news {
    width: 100%;
    padding: 0px;
    margin: 0%;
    background-color: #ffffff;
  }
  .latest-news p {
    font-size: 7.5px;
    margin-top: -9px;
  }
  .img-read {
    width: 100%;
  }
  .cardzz {
    flex: 100%;
    /* height: 16.2%; */
    flex-wrap: wrap;
    border-radius: 10px;
  }
  .card2 {
    height: 7%;
  }
  .card-content h3 {
    margin-top: -8px;
    margin-bottom: 10px;
    font-size: 17px;
    line-height: 31px;
  }
  .card-content2 h3 {
    font-size: 11px;
  }
  .card-content2 p {
    margin-bottom: -5px;
    font-size: 7px;
  }
  .card-content {
    flex: 40%;
    padding: 10px;
  }
  .card-image2 {
    flex: 40%;
    max-width: 40%;
  }
  .card-image2 img {
    flex: 100%;
    max-width: 100%;
  }
  .icon-container img {
    margin: 5px;
    width: 17%;
    height: 14px;
  }

  .image-text-center {
    font-size: 40px;
    margin-top: 20px;
  }
  .button-fl {
    padding: 10px 10px;
    display: flex;
    justify-content: space-evenly;
    margin-top: 18px;
    margin-bottom: 7px;
  }

  .upcomingbutton.active {
    font-size: 10px;
    border-radius: 7px;
    padding: 15px 30px 15px 30px;
  }
  .upcomingbutton {
    font-size: 13px;
    border-radius: 5px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .content.active {
    display: block;
    padding: 0px 5px;
  }
  .card-image {
    width: 100%;
  }
  .paraidnfons {
    margin-top: 0px;
    font-size: 14px;
    width: 100%;
    overflow: auto;
    height: auto;
    line-height: 24px;
  }
  .icon-formeo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    /* margin: 0px 0px 0px 0px; */
  }
  .iconds {
    margin: 0%;
    color: #232b70;
    font-size: 13px;
    line-height: 28px;
    margin-left: 5px;
    font-weight: 400;
  }
  .card-image img {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .ioocin {
    width: 20px;
    height: 20px;
  }
  .osnofnsl {
    margin-top: -10px;
    color: #888;
    font-size: 12px;
    line-height: 23px;
  }
}

@media (min-width: 520px) and (max-width: 900px) {
  .cardzz {
    display: flex;
    flex-wrap: nowrap;
    height: 170px;
    /* flex: 48%; */
    flex-direction: row;
    background-color: white;
    box-shadow: 0px 1px 5px 0px #0000001a;
    margin-bottom: 20px;
    overflow: hidden;
  }
  .card-image img {
    max-width: 197px;
    height: 170px;
  }
  .content.active {
    display: block;
    padding: 0px 30px;
  }
  .paraidnfons {
    margin-top: 0px;
    height: 65px;
    overflow: auto;
    font-size: 11px;
  }
  .image-text-center {
    font-size: 60px;
    margin-top: 20px;
  }
  .button-fl {
    padding: 60px 50px;
  }
  .card-image {
    /* width: 351px; */
    height: 243px;
  }
  .card-content h3 {
    margin-top: 0;
    font-size: 18px;
    margin-bottom: 5px;
  }
  .osnofnsl {
    margin-top: -10px;
    color: #707180;
    font-size: 12px;
  }
  .card-content {
    flex: 40%;
    padding: 15px;
    box-sizing: border-box;
  }
  .icon-formeo {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px 0px 0px;
  }
  .iconpari {
    display: flex;
    /* align-items: baseline; */
  }

  .iconds {
    margin: 0%;
    color: #232b70;
    font-size: 11px;
    margin-left: 7px;
    font-weight: 400;
  }
  .ioocin {
    width: 20px;
    height: 20px;
  }
}

/* foooter css////////////////////////////////////////////////////////// */

/*-----------------------------------------------------footer----------------------------------------*/

.footerMragintop h1 {
  border-bottom: 1px solid #766d6d;
  margin-left: 90px;
  margin-right: 90px;
}

@media (max-width: 575.98px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: center;
    color: #ffffff;
    gap: 28px;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 0px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 4px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
    font-size: 10px;
  }
  .footercontents {
    /* margin-top: 13px; */
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
    font-size: 8px;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
    margin-top: 0px;
  }

  .copyrightsflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 div img {
    width: 68.76px;
    height: 33.68px;

    padding: 0px, 0px, 0px, 0px;
  }
  .footercontents {
    margin-top: -7px;
    font-weight: 500;
  }

  .copyrightsflex span {
    color: #ffffff;
    font-size: 4px;
  }
  .copyrightssection .copyrightsflex img {
    width: 3.92px;
    height: 4.09px;
  }
  /*--------------------------------Footer Section ----------------------*/
}
@media (min-width: 576px) and (max-width: 767.98px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: center;
    gap: 35px;
    color: #ffffff;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersection1 img {
    width: 120px;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
    font-size: 18px;
  }
  .footercontents {
    margin-top: 3px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
    font-size: 14px;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }
  .copyrightsflex span {
    font-size: 10px;
  }
  .copyrightsflex img {
    width: 13px;
  }

  /*--------------------------------Footer Section ----------------------*/
}
@media (min-width: 768px) and (max-width: 991.98px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: center;
    color: #ffffff;
    gap: 75px;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
    font-size: 17px;
  }
  .footercontents {
    margin-top: 13px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
    font-size: 13px;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .copyrightsflex span {
    font-size: 12px;
  }

  .copyrightsflex img {
    width: 13px;
  }

  /*--------------------------------Footer Section ----------------------*/
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: space-around;
    color: #ffffff;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
  }
  .footercontents {
    margin-top: 13px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  /*--------------------------------Footer Section ----------------------*/
}

@media (min-width: 1200px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: center;
    column-gap: 215px;
    color: #ffffff;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
    padding-bottom: 30px;
    padding-top: 100px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
    text-decoration: none;
  }
  .footercontents {
    margin-top: 13px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    text-decoration: none;
    background: none;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    margin-top: 1px;
  }
  .copyrightsflex img {
    width: 10px;
  }
  .copyrightsflex span {
    color: #ffffff;
    font-size: 12px;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .copyrightssection {
    display: flex;
    justify-content: center;
    gap: 500px;
  }
  /*--------------------------------Footer Section ----------------------*/
}

@media (min-width: 1440px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: space-evenly;
    color: #ffffff;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
    padding-bottom: 40px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
  }
  .footercontents {
    margin-top: 13px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    margin-top: 1px;
  }
  .copyrightsflex img {
    width: 10px;
  }
  .copyrightsflex span {
    color: #ffffff;
    font-size: 12px;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .copyrightssection {
    display: flex;
    justify-content: center;
    gap: 500px;
  }
  /*--------------------------------Footer Section ----------------------*/
}

/*------------------------------------------------------footer-------------------------------*/

@media (max-width: 600px) {
  /* .mobileversionfornrentriflex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 9px;
    margin-top: 30px;
  } */

  .containermobiileversionforcards {
    position: relative;
    width: 80%;
  }

  .imagesdasansns {
    display: block;
    width: 100%;
    height: auto;
  }

  .racingggsssbutton a {
    text-decoration: none;
    color: #fff;
  }

  .overlaysadsddsdsd {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .containermobiileversionforcards:hover .overlaysadsddsdsd {
    opacity: 1;
  }

  .textsadsddsddsd {
    font-family: "Poppins";
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .racingggsssbutton {
    width: 175px;
    height: 30px;
    background-color: transparent;
    border: 2px solid blue;
    margin-top: 15px;
    color: #fff;
    font-size: 16px;
    font-family: "Poppins";
  }

  .nameofthecupname {
    font-size: 20px;
  }
}
@media (min-width: 1358px) {
}

@media (min-width: 1536px) {
  .revnitroteamssszz {
    display: none;
  }
}

@media (min-width: 1700px) {
  .revnitroteamssszz {
    display: none;
  }
}

@media (min-width: 1920px) {
  .revnitroteamssszz {
    display: none;
  }
}

@media (max-width: 600px) {
  .mobilehidden {
    display: none;
  }
}

/*--------------------------------------------Navbar Section----------------------------*/

@media (max-width: 600px) {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap");

  .dektopnavbarsssforrevnitor {
    display: none;
  }
  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  .navbar {
    background: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    z-index: 16;
  }
  .hamburger {
    display: none;
  }

  .bar {
    /* color: wheat; */
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #ffffff;
  }
  .nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-item {
    margin-left: 5rem;
  }

  .nav-link {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }

  .nav-link:hover {
    color: #482ff7;
  }

  .nav-logo {
    font-size: 2.1rem;
    font-weight: 500;
    color: #482ff7;
  }

  @media only screen and (max-width: 768px) {
    .nav-menu {
      position: fixed;
      left: -120%;
      top: 5rem;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      border-radius: 10px;
      text-align: center;
      transition: 0.3s;
      box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    }

    .nav-menu.active {
      left: 0;
      background: black;
      padding: 0px;
      z-index: 9999999;
    }

    .nav-item {
      margin: 20px;
      margin-top: 3px;
      margin-bottom: 10px;
      /* margin: 20px; */
    }

    .hamburger {
      display: block;
      cursor: pointer;
    }
    .hamburger.active .bar:nth-child(2) {
      opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}
@media (min-width: 601px) and (max-width: 750px) {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap");

  .dektopnavbarsssforrevnitor {
    display: none;
  }
  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  .navbar {
    background: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    z-index: 16;
  }
  .hamburger {
    display: none;
  }

  .bar {
    /* color: wheat; */
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #ffffff;
  }
  .nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-item {
    margin-left: 5rem;
  }

  .nav-link {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }

  .nav-link:hover {
    color: #482ff7;
  }

  .nav-logo {
    font-size: 2.1rem;
    font-weight: 500;
    color: #482ff7;
  }

  @media only screen and (max-width: 768px) {
    .nav-menu {
      position: fixed;
      left: -100%;
      top: 5rem;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      border-radius: 10px;
      text-align: center;
      transition: 0.3s;
      box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    }

    .nav-menu.active {
      left: 0;
      background: black;
      padding: 0px;
    }

    .nav-item {
      margin: 20px;
      margin-top: 3px;
      margin-bottom: 10px;
      /* margin: 20px; */
    }

    .hamburger {
      display: block;
      cursor: pointer;
    }
    .hamburger.active .bar:nth-child(2) {
      opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}

@media (min-width: 750px) and (max-width: 900px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 13px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 13px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 16px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 16px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}
@media (min-width: 1200px) and (max-width: 1354px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 16px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}

@media (min-width: 1355px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 16px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}
@media (min-width: 1534px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 96px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 21px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 21px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}
@media (min-width: 1919px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 100px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 24px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 24px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}

/*--------------------------------------------Navbar Section----------------------------*/

body {
  margin: 0%;
  font-family: Poppins;
}
.revnitrobodyblack {
  background-color: #000000;
}

.inneorn-contaidowr {
  margin: 5%;
}
.image-backing {
  margin-right: 10px;
}
.backbuttonicon {
  display: flex;
  margin: 20px 0px;
}
.iconfidxiend {
  font-size: 20px;
}
.eiodnf {
  margin: 0;
  font-size: 20px;
  margin-top: -5px;
}

.image-withith {
  width: 100%;
  border-radius: 10px;
}
.icon-florxindig {
  display: flex;
  justify-content: space-between;
}
.iconandword {
  display: flex;
  align-items: center;
}
.aondofj {
  display: flex;
  justify-content: space-between;
}
.icon-sizeindos {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.ook-solod {
  display: flex;
  align-items: end;
  background-color: #232b70;
  color: #ffffff;
  font-size: 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  padding: 7px;
  font-weight: 500;
  padding-right: 10px;
}
@media (max-width: 768px) {
  .aondofj {
    display: block;
    justify-content: space-between;
  }
  .icon-sizeindos {
    width: 20px;
    height: 20px;
  }
  .aondpapara {
    font-size: 12px;
  }
}

/*--------------------------------------------Navbar Section----------------------------*/

@media (max-width: 600px) {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap");

  .dektopnavbarsssforrevnitor {
    display: none;
  }
  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  .navbar {
    background: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    z-index: 16;
  }
  .hamburger {
    display: none;
  }

  .bar {
    /* color: wheat; */
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #ffffff;
  }
  .nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-item {
    margin-left: 5rem;
  }

  .nav-link {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }

  .nav-link:hover {
    color: #482ff7;
  }

  .nav-logo {
    font-size: 2.1rem;
    font-weight: 500;
    color: #482ff7;
  }

  @media only screen and (max-width: 768px) {
    .nav-menu {
      position: fixed;
      left: -120%;
      top: 5rem;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      border-radius: 10px;
      text-align: center;
      transition: 0.3s;
      box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    }

    .nav-menu.active {
      left: 0;
      background: black;
      padding: 0px;
      z-index: 9999999;
    }

    .nav-item {
      margin: 20px;
      margin-top: 3px;
      margin-bottom: 10px;
      /* margin: 20px; */
    }

    .hamburger {
      display: block;
      cursor: pointer;
    }
    .hamburger.active .bar:nth-child(2) {
      opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}
@media (min-width: 601px) and (max-width: 750px) {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap");

  .dektopnavbarsssforrevnitor {
    display: none;
  }
  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  .navbar {
    background: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    z-index: 16;
  }
  .hamburger {
    display: none;
  }

  .bar {
    /* color: wheat; */
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #ffffff;
  }
  .nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-item {
    margin-left: 5rem;
  }

  .nav-link {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }

  .nav-link:hover {
    color: #482ff7;
  }

  .nav-logo {
    font-size: 2.1rem;
    font-weight: 500;
    color: #482ff7;
  }

  @media only screen and (max-width: 768px) {
    .nav-menu {
      position: fixed;
      left: -100%;
      top: 5rem;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      border-radius: 10px;
      text-align: center;
      transition: 0.3s;
      box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    }

    .nav-menu.active {
      left: 0;
      background: black;
      padding: 0px;
    }

    .nav-item {
      margin: 20px;
      margin-top: 3px;
      margin-bottom: 10px;
      /* margin: 20px; */
    }

    .hamburger {
      display: block;
      cursor: pointer;
    }
    .hamburger.active .bar:nth-child(2) {
      opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}

@media (min-width: 750px) and (max-width: 900px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 13px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 13px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 16px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 16px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}
@media (min-width: 1200px) and (max-width: 1354px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 16px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}

@media (min-width: 1355px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 16px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}
@media (min-width: 1534px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 96px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 21px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 21px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}
@media (min-width: 1919px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 100px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 24px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 24px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}

/*--------------------------------------------Navbar Section----------------------------*/

/* foooter css////////////////////////////////////////////////////////// */

/*-----------------------------------------------------footer----------------------------------------*/

.footerMragintop h1 {
  border-bottom: 1px solid #766d6d;
  margin-left: 90px;
  margin-right: 90px;
}

@media (max-width: 575.98px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: center;
    color: #ffffff;
    gap: 28px;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 0px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 4px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
    font-size: 10px;
  }
  .footercontents {
    /* margin-top: 13px; */
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
    font-size: 8px;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
    margin-top: 0px;
  }

  .copyrightsflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 div img {
    width: 68.76px;
    height: 33.68px;

    padding: 0px, 0px, 0px, 0px;
  }
  .footercontents {
    margin-top: -7px;
    font-weight: 500;
  }

  .copyrightsflex span {
    color: #ffffff;
    font-size: 4px;
  }
  .copyrightssection .copyrightsflex img {
    width: 3.92px;
    height: 4.09px;
  }
  /*--------------------------------Footer Section ----------------------*/
}
@media (min-width: 576px) and (max-width: 767.98px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: center;
    gap: 35px;
    color: #ffffff;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersection1 img {
    width: 120px;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
    font-size: 18px;
  }
  .footercontents {
    margin-top: 3px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
    font-size: 14px;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }
  .copyrightsflex span {
    font-size: 10px;
  }
  .copyrightsflex img {
    width: 13px;
  }

  /*--------------------------------Footer Section ----------------------*/
}
@media (min-width: 768px) and (max-width: 991.98px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: center;
    color: #ffffff;
    gap: 75px;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
    font-size: 17px;
  }
  .footercontents {
    margin-top: 13px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
    font-size: 13px;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .copyrightsflex span {
    font-size: 12px;
  }

  .copyrightsflex img {
    width: 13px;
  }

  /*--------------------------------Footer Section ----------------------*/
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: space-around;
    color: #ffffff;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
  }
  .footercontents {
    margin-top: 13px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  /*--------------------------------Footer Section ----------------------*/
}

@media (min-width: 1200px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: center;
    column-gap: 215px;
    color: #ffffff;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
    padding-bottom: 30px;
    padding-top: 100px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
    text-decoration: none;
  }
  .footercontents {
    margin-top: 13px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    text-decoration: none;
    background: none;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    margin-top: 1px;
  }
  .copyrightsflex img {
    width: 10px;
  }
  .copyrightsflex span {
    color: #ffffff;
    font-size: 12px;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .copyrightssection {
    display: flex;
    justify-content: center;
    gap: 500px;
  }
  /*--------------------------------Footer Section ----------------------*/
}

@media (min-width: 1440px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: space-evenly;
    color: #ffffff;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
    padding-bottom: 40px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
  }
  .footercontents {
    margin-top: 13px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    margin-top: 1px;
  }
  .copyrightsflex img {
    width: 10px;
  }
  .copyrightsflex span {
    color: #ffffff;
    font-size: 12px;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .copyrightssection {
    display: flex;
    justify-content: center;
    gap: 500px;
  }
  /*--------------------------------Footer Section ----------------------*/
}

/*------------------------------------------------------footer-------------------------------*/

@media (max-width: 600px) {
  /* .mobileversionfornrentriflex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 9px;
    margin-top: 30px;
  } */

  .containermobiileversionforcards {
    position: relative;
    width: 80%;
  }

  .imagesdasansns {
    display: block;
    width: 100%;
    height: auto;
  }

  .racingggsssbutton a {
    text-decoration: none;
    color: #fff;
  }

  .overlaysadsddsdsd {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .containermobiileversionforcards:hover .overlaysadsddsdsd {
    opacity: 1;
  }

  .textsadsddsddsd {
    font-family: "Poppins";
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .racingggsssbutton {
    width: 175px;
    height: 30px;
    background-color: transparent;
    border: 2px solid blue;
    margin-top: 15px;
    color: #fff;
    font-size: 16px;
    font-family: "Poppins";
  }

  .nameofthecupname {
    font-size: 20px;
  }
}
@media (min-width: 1358px) {
}

@media (min-width: 1536px) {
  .revnitroteamssszz {
    display: none;
  }
}

@media (min-width: 1700px) {
  .revnitroteamssszz {
    display: none;
  }
}

@media (min-width: 1920px) {
  .revnitroteamssszz {
    display: none;
  }
}

@media (max-width: 600px) {
  .mobilehidden {
    display: none;
  }
}

/*--------------------------------------------Navbar Section----------------------------*/

@media (max-width: 600px) {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap");

  .dektopnavbarsssforrevnitor {
    display: none;
  }
  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  .navbar {
    background: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    z-index: 16;
  }
  .hamburger {
    display: none;
  }

  .bar {
    /* color: wheat; */
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #ffffff;
  }
  .nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-item {
    margin-left: 5rem;
  }

  .nav-link {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }

  .nav-link:hover {
    color: #482ff7;
  }

  .nav-logo {
    font-size: 2.1rem;
    font-weight: 500;
    color: #482ff7;
  }

  @media only screen and (max-width: 768px) {
    .nav-menu {
      position: fixed;
      left: -120%;
      top: 5rem;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      border-radius: 10px;
      text-align: center;
      transition: 0.3s;
      box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    }

    .nav-menu.active {
      left: 0;
      background: black;
      padding: 0px;
      z-index: 9999999;
    }

    .nav-item {
      margin: 20px;
      margin-top: 3px;
      margin-bottom: 10px;
      /* margin: 20px; */
    }

    .hamburger {
      display: block;
      cursor: pointer;
    }
    .hamburger.active .bar:nth-child(2) {
      opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}
@media (min-width: 601px) and (max-width: 750px) {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap");

  .dektopnavbarsssforrevnitor {
    display: none;
  }
  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  .navbar {
    background: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    z-index: 16;
  }
  .hamburger {
    display: none;
  }

  .bar {
    /* color: wheat; */
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #ffffff;
  }
  .nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-item {
    margin-left: 5rem;
  }

  .nav-link {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }

  .nav-link:hover {
    color: #482ff7;
  }

  .nav-logo {
    font-size: 2.1rem;
    font-weight: 500;
    color: #482ff7;
  }

  @media only screen and (max-width: 768px) {
    .nav-menu {
      position: fixed;
      left: -100%;
      top: 5rem;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      border-radius: 10px;
      text-align: center;
      transition: 0.3s;
      box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    }

    .nav-menu.active {
      left: 0;
      background: black;
      padding: 0px;
    }

    .nav-item {
      margin: 20px;
      margin-top: 3px;
      margin-bottom: 10px;
      /* margin: 20px; */
    }

    .hamburger {
      display: block;
      cursor: pointer;
    }
    .hamburger.active .bar:nth-child(2) {
      opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}

@media (min-width: 750px) and (max-width: 900px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 13px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 13px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 16px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 16px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}
@media (min-width: 1200px) and (max-width: 1354px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 16px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}

@media (min-width: 1355px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 16px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}
@media (min-width: 1534px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 96px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 21px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 21px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}
@media (min-width: 1919px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 100px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 24px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 24px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}

/*--------------------------------------------Navbar Section----------------------------*/
/* <!---------------------CreateYourPost-------------------------------------> */
.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  background-color: #eeedf2;
  top: 399px;
  left: 321px;
  height: 17px;
  width: 149px;
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

.uploadimagecreatepost {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #2200ff;
  margin-left: 5px;
  margin-top: 2px;
}

.CreateYourPost {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  margin-left: 30px;
}

.createpostfunction {
  display: flex;
  justify-content: center;
  column-gap: 30px;
  margin-top: 30px;
}

.rightcreatepost {
  width: 951px;
  border-radius: 10px;
  border: 2px;
  background: #f2f1f8;
}

.createpostdiv {
  width: 951px;
  border-radius: 10px;
  border: 2px;
  background-color: #fff;
  height: auto;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

label[for="createpost"] {
  position: relative;
  display: block;
  width: 586px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  background-color: #eeedf2;
}

label[for="createpost"]::after {
  content: " ";
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 100%;
  display: block;
  font-family: Poppins;
  background: #eeedf2
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOBAMAAAGq7RFVAAAAJ1BMVEX///////////////////////////////////////////////////9Ruv0SAAAADHRSTlMACAoREoKDt7y9zc5qtZoyAAAAcklEQVQIHQXBsQnCUAAFwFOCCKbIABaWERzi1yaFAzmEpHYEC8GUKVQEkbyhvOOkZqdWt0/QYKqLzQeF7dv6y/RLwa2wYtk4Xxxe+t8yd1WuM/rcUc2gYHEeoP02umSgTR50ybBPRuiSZAS6ZAQ4jgCAPyjYJiRH4fymAAAAAElFTkSuQmCC")
    no-repeat center center;
  pointer-events: none;
  color: black;
}

label[for="createpost"] select {
  height: 43px;
  width: 100%;
  border: none;
  background: #eeedf2;
  padding: 7px 20px 7px 20px;
  font-size: 16px;
  font-family: monospace;
  cursor: pointer;
  font-family: Poppins;
}

label[for="createpost"] select::-ms-expand {
  display: none;
}

label[for="createpost"] :-moz-any(select) {
  width: 110%;
}

label[for="createpost"].ie9 select {
  width: 110%;
}

label[for="createpost"] select:focus {
  outline: 1px dotted #a9a9a9;
}

.cforumtopics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 50px;
  padding-left: 105px;
  padding-right: 70px;
  margin-top: 30px;
}

.cheadingtopics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 50px;
  padding-left: 105px;
  padding-right: 70px;
  margin-top: 30px;
}
.ctextareapostcreate {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 50px;
  padding-left: 105px;
  padding-right: 70px;
  margin-top: 30px;
  position: relative;
}
.cinputforumcreatepost input {
  width: 562px;
  height: 43px;
  border-radius: 10px;
  background-color: #eeedf2;
  border: none;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 20px;
  color: #000000;
}
.cinputforumcreatepost input::placeholder {
  color: #000000;
}

.textareacreatepost textarea {
  width: 562px;
  height: 297px;
  background-color: #eeedf2;
  border: none;
  border-radius: 10px;
}

.headingcreatepost {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.textareacreatepost textarea {
  font-family: Poppins;
  padding-left: 20px;
  padding-top: 15px;
  font-size: 16px;
}

.photouploadsimagers {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  background-color: #ffffff;
  border: none;
  width: 88px;
  height: 33px;
  border-radius: 7px;
  position: absolute;
  top: 411px;
  left: 319px;
}
#file {
  display: none;
}
.formuploadflex {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}

.Deleteofthihbsvddg {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 3px;
  margin-left: 28px;
  margin-top: 10px;
}

.deletebuttifdgbshgfdhss {
  height: 21px;
}

.DeleteImageprofilejbhdf {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #ff0000;
}

.uploadtexts {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #2200ff;
}
.postionofimage {
  position: relative;
}

.buttonsubmit button {
  width: 173px;
  height: 40px;
  background-color: #2200ff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
  text-align: center;
  color: #ffffff;
  border-radius: 10px;
  margin-left: 298px;
  margin-top: 30px;
}
.buttonsubmit {
  padding-bottom: 50px;
}

.fileuploadareainput {
  background-color: transparent;
  border: none;
}

/*-----------Recently Added--------------*/

.previewwimagesizee {
  width: 200px;
  height: 78px;
}
.recommenededsizees {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #8c8484;
  margin-top: 10px;
}

.thumbnailtextscreatepost {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.thumbnailsecrionforcreatepost {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 50px;
  padding-left: 105px;
  padding-right: 254px;
  margin-top: 30px;
}

.thumbnailsecrionforcreatepost11 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 50px;
  padding-left: 105px;
  padding-right: 435px;
  margin-top: 30px;
}

.imagethumbnailpreviewdivtag {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  align-items: flex-start;
}

.recomennededsizeforphoto {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #8c8484;
  margin-left: 303px;
  margin-top: 10px;
}

.breiefdescriptionnamee {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.breifdescriptionzsflex {
  display: flex;
  padding-left: 105px;
  margin-top: 30px;
}

.breifedescriptiontrextareabox {
  padding-left: 35px;
}
.breifedescriptiontrextareabox textarea {
  width: 573px;
  background-color: #eeedf2;
  border: none;
  border-radius: 10px;
  height: 180px;
  padding: 10px;
  padding-left: 20px;
  color: #9d9d9d;
  font-size: 20px;
}

.breifedescriptiontrextareabox textarea::placeholder {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

/*-----------Recently Added--------------*/

/* <!---------------------CreateYourPost-------------------------------------> */
.cg2g3g23g23g33 {
  width: 1000px;
}
.cretyeteh3h434 {
  display: flex;
  justify-content: center;
}

.createpostheadingdiv {
  text-align: center;
  font-size: 30px;
}
body {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Poppins";
}

.active {
  color: #f00 !important;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  color: white;
  position: sticky;
  top: 0;
  padding-right: 20px;
  padding-bottom: 0px;
  z-index: 3;
}

nav .title {
  font-size: 16px;
  margin: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: white;
  margin-bottom: 6px;
}

nav ul {
  display: flex;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: #fff;
  margin: 0 0.5rem;
  border-radius: 6px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 500;
  line-height: 29.06px;
}

nav ul li a:not(.active):hover {
  color: #f00 !important;
}

nav .menu {
  display: none;
  position: absolute;
  top: 22px;
  right: 1.7rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2rem;
  height: 24px;
}

nav .menu span {
  height: 4px;
  width: 34px;
  background-color: #fff;
  border-radius: 0.2rem;
  margin-bottom: 0px;
}

@media (max-width: 900px) {
  .active {
    color: #f00 !important;
  }
  .ImageNavbrsixecontrol {
    height: 35px;
  }
  nav .menu {
    display: flex;
    text-align: center;
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.45rem;
    margin-top: 0px;
  }

  nav ul.open {
    display: flex;
    padding: 0px;
  }

  nav ul li {
    width: 100%;
    text-align: center;
  }

  nav ul li a {
    margin: 0.2rem 0.5rem;
    margin: 0.2rem 0.5rem;
    font-size: 14px;
    line-height: 23px;
  }
}
a {
  text-decoration: none;
}
/*-----------------------------------------------------footer----------------------------------------*/

.footerMragintop h1 {
  border-bottom: 1px solid #766d6d;
  margin-left: 90px;
  margin-right: 90px;
}

@media (max-width: 575.98px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: center;
    color: #ffffff;
    gap: 28px;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 0px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 4px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
    font-size: 10px;
  }
  .footercontents {
    /* margin-top: 13px; */
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
    font-size: 8px;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
    margin-top: 0px;
  }

  .copyrightsflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 div img {
    width: 68.76px;
    height: 33.68px;

    padding: 0px, 0px, 0px, 0px;
  }
  .footercontents {
    margin-top: -7px;
    font-weight: 500;
  }

  .copyrightsflex span {
    color: #ffffff;
    font-size: 4px;
  }
  .copyrightssection .copyrightsflex img {
    width: 3.92px;
    height: 4.09px;
  }
  /*--------------------------------Footer Section ----------------------*/
}
@media (min-width: 576px) and (max-width: 767.98px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: center;
    gap: 35px;
    color: #ffffff;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersection1 img {
    width: 120px;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
    font-size: 18px;
  }
  .footercontents {
    margin-top: 3px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
    font-size: 14px;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }
  .copyrightsflex span {
    font-size: 10px;
  }
  .copyrightsflex img {
    width: 13px;
  }

  /*--------------------------------Footer Section ----------------------*/
}
@media (min-width: 768px) and (max-width: 991.98px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: center;
    color: #ffffff;
    gap: 75px;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
    font-size: 17px;
  }
  .footercontents {
    margin-top: 13px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
    font-size: 13px;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .copyrightsflex span {
    font-size: 12px;
  }

  .copyrightsflex img {
    width: 13px;
  }

  /*--------------------------------Footer Section ----------------------*/
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: space-around;
    color: #ffffff;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
  }
  .footercontents {
    margin-top: 13px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  /*--------------------------------Footer Section ----------------------*/
}

@media (min-width: 1200px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: center;
    column-gap: 215px;
    color: #ffffff;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
    padding-bottom: 30px;
    padding-top: 100px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
  }
  .footercontents {
    margin-top: 13px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    margin-top: 1px;
  }
  .copyrightsflex img {
    width: 10px;
  }
  .copyrightsflex span {
    color: #ffffff;
    font-size: 12px;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .copyrightssection {
    display: flex;
    justify-content: center;
    gap: 500px;
  }
  /*--------------------------------Footer Section ----------------------*/
}

@media (min-width: 1440px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: space-evenly;
    color: #ffffff;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
    padding-bottom: 40px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
  }
  .footercontents {
    margin-top: 13px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    margin-top: 1px;
  }
  .copyrightsflex img {
    width: 10px;
  }
  .copyrightsflex span {
    color: #ffffff;
    font-size: 12px;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .copyrightssection {
    display: flex;
    justify-content: center;
    gap: 500px;
  }
  /*--------------------------------Footer Section ----------------------*/
}

/*------------------------------------------------------footer-------------------------------*/
body {
  margin: 0%;
  font-family: Poppins;
  text-decoration: none;
}
.revnitrobodyblack {
  background-color: #000000;
}

/* formmmmmmmmmmmmmmmmmmm//////////////////////////////////////////////////////////////////////// */
.divallforme {
  display: flex;
  justify-content: center;
}
.form-container1 {
  padding: 2rem;
  border-radius: 8px;

  max-width: 500px;
  width: 100%;
}

h1 {
  text-align: center;
  margin-bottom: 1rem;
}

.form-group1 {
  margin-bottom: 1rem;
}

.form-group1 label {
  display: block;
  color: #808080;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.form-group1 input[type="text"],
.form-group1 input[type="tel"],
.form-group1 input[type="email"],
.form-group1 select {
  width: 100%;
  padding: 0.6rem;

  border: 2px solid #8487a2;
  border-radius: 4px;
}
.form-group1 select {
  width: 104.5%;
  margin-top: 10px;
}
.form-group1 input[type="checkbox"] {
  margin-left: 0.5rem;
}

button[type="submit"] {
  /* width: 30%; */
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #232b70;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
}

@media (max-width: 600px) {
  .form-container {
    padding: 1rem;
  }
  .form-group1 input[type="text"],
  .form-group1 input[type="tel"],
  .form-group1 input[type="email"] {
    width: 96%;
    padding: 0.5rem;
    border: 2px solid #8487a2;
    border-radius: 4px;
  }
}

/* card css/////////////////////////////////////////////////////////////////////////// */

/* foooter css////////////////////////////////////////////////////////// */

/*-----------------------------------------------------footer----------------------------------------*/

.footerMragintop h1 {
  border-bottom: 1px solid #766d6d;
  margin-left: 90px;
  margin-right: 90px;
}

@media (max-width: 575.98px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: center;
    color: #ffffff;
    gap: 28px;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 0px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 4px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
    font-size: 10px;
  }
  .footercontents {
    /* margin-top: 13px; */
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
    font-size: 8px;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
    margin-top: 0px;
  }

  .copyrightsflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 div img {
    width: 68.76px;
    height: 33.68px;

    padding: 0px, 0px, 0px, 0px;
  }
  .footercontents {
    margin-top: -7px;
    font-weight: 500;
  }

  .copyrightsflex span {
    color: #ffffff;
    font-size: 4px;
  }
  .copyrightssection .copyrightsflex img {
    width: 3.92px;
    height: 4.09px;
  }
  /*--------------------------------Footer Section ----------------------*/
}
@media (min-width: 576px) and (max-width: 767.98px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: center;
    gap: 35px;
    color: #ffffff;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersection1 img {
    width: 120px;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
    font-size: 18px;
  }
  .footercontents {
    margin-top: 3px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
    font-size: 14px;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }
  .copyrightsflex span {
    font-size: 10px;
  }
  .copyrightsflex img {
    width: 13px;
  }

  /*--------------------------------Footer Section ----------------------*/
}
@media (min-width: 768px) and (max-width: 991.98px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: center;
    color: #ffffff;
    gap: 75px;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
    font-size: 17px;
  }
  .footercontents {
    margin-top: 13px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
    font-size: 13px;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .copyrightsflex span {
    font-size: 12px;
  }

  .copyrightsflex img {
    width: 13px;
  }

  /*--------------------------------Footer Section ----------------------*/
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: space-around;
    color: #ffffff;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
  }
  .footercontents {
    margin-top: 13px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  /*--------------------------------Footer Section ----------------------*/
}

@media (min-width: 1200px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: center;
    column-gap: 215px;
    color: #ffffff;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
    padding-bottom: 30px;
    padding-top: 100px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
    text-decoration: none;
  }
  .footercontents {
    margin-top: 13px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    text-decoration: none;
    background: none;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    margin-top: 1px;
  }
  .copyrightsflex img {
    width: 10px;
  }
  .copyrightsflex span {
    color: #ffffff;
    font-size: 12px;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .copyrightssection {
    display: flex;
    justify-content: center;
    gap: 500px;
  }
  /*--------------------------------Footer Section ----------------------*/
}

@media (min-width: 1440px) {
  /*--------------------------------Footer Section ----------------------------*/

  .revnitroFooter {
    display: flex;
    justify-content: space-evenly;
    color: #ffffff;
  }

  .footersection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
  }

  .footersss {
    background-color: #000000;
    padding: 30px;
    padding-bottom: 40px;
  }

  .headingdiv {
    font-size: 23px;
    margin-bottom: 30px;
    font-weight: 580;
  }
  .headingdiv a {
    color: #ffffff;
    background: none;
  }
  .footercontents {
    margin-top: 13px;
    font-weight: 500;
  }
  .footercontents a {
    /* color: #ffffff; */
    color: #ffffff;
    background: none;
  }
  .footercontents a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .footersection1 {
    justify-content: center;
  }

  .copyrightsflex {
    margin-top: 1px;
  }
  .copyrightsflex img {
    width: 10px;
  }
  .copyrightsflex span {
    color: #ffffff;
    font-size: 12px;
  }
  .headingdiv a:hover {
    margin-top: 13px;
    font-weight: 500;
    color: #f00;
    cursor: pointer;
    text-decoration: none;
  }

  .copyrightssection {
    display: flex;
    justify-content: center;
    gap: 500px;
  }
  /*--------------------------------Footer Section ----------------------*/
}

/*------------------------------------------------------footer-------------------------------*/

@media (max-width: 600px) {
  /* .mobileversionfornrentriflex {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 9px;
      margin-top: 30px;
    } */

  .containermobiileversionforcards {
    position: relative;
    width: 80%;
  }

  .imagesdasansns {
    display: block;
    width: 100%;
    height: auto;
  }

  .racingggsssbutton a {
    text-decoration: none;
    color: #fff;
  }

  .overlaysadsddsdsd {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .containermobiileversionforcards:hover .overlaysadsddsdsd {
    opacity: 1;
  }

  .textsadsddsddsd {
    font-family: "Poppins";
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .racingggsssbutton {
    width: 175px;
    height: 30px;
    background-color: transparent;
    border: 2px solid blue;
    margin-top: 15px;
    color: #fff;
    font-size: 16px;
    font-family: "Poppins";
  }

  .nameofthecupname {
    font-size: 20px;
  }
}
@media (min-width: 1358px) {
}

@media (min-width: 1536px) {
  .revnitroteamssszz {
    display: none;
  }
}

@media (min-width: 1700px) {
  .revnitroteamssszz {
    display: none;
  }
}

@media (min-width: 1920px) {
  .revnitroteamssszz {
    display: none;
  }
}

@media (max-width: 600px) {
  .mobilehidden {
    display: none;
  }
}

/*--------------------------------------------Navbar Section----------------------------*/

@media (max-width: 600px) {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap");

  .dektopnavbarsssforrevnitor {
    display: none;
  }
  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  .navbar {
    background: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    z-index: 16;
  }
  .hamburger {
    display: none;
  }

  .bar {
    /* color: wheat; */
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #ffffff;
  }
  .nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-item {
    margin-left: 5rem;
  }

  .nav-link {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }

  .nav-link:hover {
    color: #482ff7;
  }

  .nav-logo {
    font-size: 2.1rem;
    font-weight: 500;
    color: #482ff7;
  }

  @media only screen and (max-width: 768px) {
    .nav-menu {
      position: fixed;
      left: -120%;
      top: 5rem;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      border-radius: 10px;
      text-align: center;
      transition: 0.3s;
      box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    }

    .nav-menu.active {
      left: 0;
      background: black;
      padding: 0px;
      z-index: 9999999;
    }

    .nav-item {
      margin: 20px;
      margin-top: 3px;
      margin-bottom: 10px;
      /* margin: 20px; */
    }

    .hamburger {
      display: block;
      cursor: pointer;
    }
    .hamburger.active .bar:nth-child(2) {
      opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}
@media (min-width: 601px) and (max-width: 750px) {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap");

  .dektopnavbarsssforrevnitor {
    display: none;
  }
  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  .navbar {
    background: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    z-index: 16;
  }
  .hamburger {
    display: none;
  }

  .bar {
    /* color: wheat; */
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #ffffff;
  }
  .nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-item {
    margin-left: 5rem;
  }

  .nav-link {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }

  .nav-link:hover {
    color: #482ff7;
  }

  .nav-logo {
    font-size: 2.1rem;
    font-weight: 500;
    color: #482ff7;
  }

  @media only screen and (max-width: 768px) {
    .nav-menu {
      position: fixed;
      left: -100%;
      top: 5rem;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      border-radius: 10px;
      text-align: center;
      transition: 0.3s;
      box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    }

    .nav-menu.active {
      left: 0;
      background: black;
      padding: 0px;
    }

    .nav-item {
      margin: 20px;
      margin-top: 3px;
      margin-bottom: 10px;
      /* margin: 20px; */
    }

    .hamburger {
      display: block;
      cursor: pointer;
    }
    .hamburger.active .bar:nth-child(2) {
      opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}

@media (min-width: 750px) and (max-width: 900px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 13px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 13px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 16px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 16px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}
@media (min-width: 1200px) and (max-width: 1354px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 16px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}

@media (min-width: 1355px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 74px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 16px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}
@media (min-width: 1534px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 96px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 21px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 21px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}
@media (min-width: 1919px) {
  .mobileNavbarforRevnitro {
    display: none;
  }
  .revnitronavbarflexxx {
    display: flex;
    justify-content: space-between;
    height: 100px;
    align-items: center;
  }
  .navbarsecondflexconcept {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    padding-right: 30px;
  }
  .navbarlogoforrevnitro {
    padding-left: 30px;
  }

  .navbarsecondflexconcept div a {
    font-size: 24px;
    color: #ffffff;
    text-decoration: none;
    font-family: "Poppins";
  }
  .navbarsecondflexconcept div a:hover {
    font-size: 24px;
    color: #f00;
    text-decoration: none;
    font-family: "Poppins";
  }
}

/*--------------------------------------------Navbar Section----------------------------*/
